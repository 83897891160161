<template>
  <div class="businessCooperation">
    <div class="navBar">
      <div @click="backPage">
        <svg-icon class="navArrow" iconClass="navArrow" />
      </div>
      <source />
      <div class="navTitle">官方社群</div>
      <div class="rightBtn"></div>
    </div>
    <div class="main">
      <div
        v-for="item in groupList"
        :key="item.officialImg"
        @click="openUrl(item.officialUrl)"
      >
        <ImgDecypt class="itemBtn" :src="item.officialImg" />
      </div>
      <div class="tip">
        如有疑问请咨询
        <router-link tag="span" to="/customerService">在线客服</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import ImgDecypt from "@/components/ImgDecypt";
import { qyeryOfficial } from "@/api/mine";
export default {
  components: {
    ImgDecypt,
  },
  data() {
    return {
      tgUrl: "",
      potatoUrl: "",
      twitterUrl: "",
      groupList: [],
    };
  },
  created() {
    this.qyeryOfficial();
  },
  methods: {
    async qyeryOfficial() {
      let res = await this.$Api(qyeryOfficial, {
        type: 2,
      });
      if (res && res.code == 200) {
        this.groupList = res.data || [];
      }
    },
    // 返回
    backPage() {
      this.$router.go(-1);
    },
    openUrl(url) {
      setTimeout(() => {
        window.open(url);
      });
      // location = url
    },
  },
};
</script>

<style lang="scss" scoped>
.businessCooperation {
  height: 100%;
  // background: #f5f5f5;

  .navBar {
    height: 44px;
    padding: 0 12px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    border-bottom: 0.5px solid #f3f3f3;

    .navArrow {
      width: 17px;
      height: 17px;
    }

    .navTitle {
      flex: 2;
      text-align: center;
      font-size: 16px;
      color: #000;
    }

    .rightBtn {
      width: 17px;
      height: 17px;
    }
  }

  .main {
    height: calc(100% - 44px);
    overflow-y: auto;
    padding-top: 89px;
    .tip {
      font-size: 12px;
      color: #8E8D9B;
      text-align: center;
      margin: 73px auto 40px;

      span {
        color: #333;
      }
    }

    .itemBtn {
      width: 175px;
      height: 71px;
      // border-radius: 71px;
      margin: 0 auto 57px;
      /deep/ .vanImage {
        background: #00000000 !important;
      }
      /deep/ .van-image__error {
        background-color: #130f3200 !important;
      }
      /deep/ img {
        object-fit: fill !important;
      }
    }
  }
}
</style>
